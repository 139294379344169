import React, { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogContentText, Stack } from '@mui/material';
import { queryClient } from 'api/queryClient';
import { clearAuthTokens } from 'api/axiosInstance';
import { authDefaultPath } from '@/settings/config';
import ErrorIcon from '@mui/icons-material/Error';

function ReLoginDialog() {
    const [flag, setFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openDialog = () => {
        setFlag(true);
    };

    const closeDialog = () => {
        setFlag(false);
    };

    const handleLogin = () => {
        clearAuthTokens();
        window.location.href = authDefaultPath;
    };

    const setDefaultOptions = useCallback(() => {
        const defaultQueryOptions = queryClient.defaultQueryOptions();
        const defaultQueryOnError = defaultQueryOptions.onError;

        queryClient.setDefaultOptions({
            queries: {
                ...defaultQueryOptions,
                onError: (err: any) => {
                    if (err.code === 4010 || err.code === 4011 || err.code === 4013) {
                        openDialog();
                        setErrorMessage(err.message);
                        return;
                    }
                    if (defaultQueryOnError) {
                        defaultQueryOnError(err);
                    }
                },
            },
        });
    }, []);

    setDefaultOptions();

    return (
        <Dialog open={flag} onClose={closeDialog} fullWidth maxWidth='xs'>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorIcon fontSize='medium' sx={{ mr: 1 }} />
                Error
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {errorMessage}
                    <br />재 로그인 하시겠습니까?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='center' alignItems='center' flex={1} spacing={2}>
                    <Button fullWidth size='large' variant='outlined' onClick={closeDialog}>
                        취소
                    </Button>
                    <Button fullWidth size='large' variant='contained' onClick={handleLogin}>
                        확인
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default ReLoginDialog;
