import React from 'react';
import { Badge, Button, Divider, IconButton, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotificationsQuery } from 'api/hooks/notifications/notificationsQuery';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import dayjs from 'dayjs';
import { useDispatch } from 'redux/store';
import { select } from 'redux/slices/sellerAdmin';
import { add } from 'redux/slices/menu';
import { useReadNotifications } from 'api/hooks/notifications/notificationMutation';
import { enqueueSnackbar } from 'notistack';

function DashboardNotifications() {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const { mutateAsync } = useReadNotifications();

    const {
        data: { count = 0, notifications = [] },
    } = useNotificationsQuery({ is_new: true }, { placeholderData: () => ({ count: 0, notifications: [] }) });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickNotification = async (item: any) => {
        if (item.event === 'signup') {
            dispatch(select(item.payload.tenant._id));
            dispatch(
                add({
                    title: '셀러 상세 관리',
                    link: '/dashboard/information/tenantDetail',
                    element: 'LazyTenantDetailPage',
                })
            );
            handleClose();
            try {
                await mutateAsync(item._id);
            } catch (e: any) {
                enqueueSnackbar(e.message, { variant: 'error' });
            }
        }
    };

    const onClick = () => {
        dispatch(
            add({
                title: '알림 내역',
                link: '/dashboard/notification/list',
                element: 'LazyNotificationPage',
            })
        );
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                {count > 0 ? (
                    <Badge badgeContent={count} color='error' sx={{ top: 2 }}>
                        <NotificationsIcon sx={{ color: 'rgba(255, 255, 255, 0.8)' }} fontSize='medium' />
                    </Badge>
                ) : (
                    <NotificationsIcon sx={{ color: 'rgba(255, 255, 255, 0.8)' }} fontSize='medium' />
                )}
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{ sx: { borderRadius: '8px' } }}
                sx={{ zIndex: 1300 }}
            >
                <Stack direction='row' justifyContent='space-between'>
                    <Typography fontSize='18px' fontWeight={600} sx={{ p: 2 }}>
                        알림
                    </Typography>
                    <Stack direction='row' alignItems='center' sx={{ pr: 3 }}>
                        <Button onClick={onClick}>
                            <ArrowCircleRightOutlinedIcon fontSize='small' sx={{ color: 'primary.main', mr: 1 }} />
                            <Typography variant='body2' sx={{ color: 'primary.main' }}>
                                View all
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
                <MenuList sx={{ maxHeight: 300, overflowY: 'scroll', width: 400, bgcolor: 'grey.100' }}>
                    {count > 0 ? (
                        notifications?.map((item: any, index: number) => {
                            return (
                                <div key={item._id}>
                                    <MenuItem onClick={() => handleClickNotification(item)}>
                                        <Stack spacing={1}>
                                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                <Typography variant='subtitle1'>
                                                    [{item.event === 'signup' ? '셀러 가입' : ''}]
                                                </Typography>
                                                <Typography fontSize='12px'>
                                                    {dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                                </Typography>
                                            </Stack>
                                            <Typography variant='subtitle2'>{item.message}</Typography>
                                        </Stack>
                                    </MenuItem>

                                    {index === notifications.length - 1 ? '' : <Divider variant='middle' />}
                                </div>
                            );
                        })
                    ) : (
                        <MenuItem disabled>
                            <Typography variant='subtitle2' sx={{ color: 'black' }}>
                                새로 받은 알림이 없습니다.
                            </Typography>
                        </MenuItem>
                    )}
                </MenuList>
            </Popover>
        </>
    );
}

export default DashboardNotifications;
