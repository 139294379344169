import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Timeline(theme: Theme) {
    return {
        MuiTimelineItem: {
            styleOverrides: {
                root: {
                    '&:last-of-type .MuiTimelineConnector-root': {
                        backgroundColor: 'white',
                    },
                    '&:first-of-type .MuiTimelineDot-root': {
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    padding: 4,
                    margin: '8px 0',
                    boxShadow: 'none',
                    backgroundColor: theme.palette.grey[300],
                },
            },
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    // width: 1,
                    backgroundColor: theme.palette.divider,
                },
            },
        },
    };
}
