import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Logo from 'components/aseets/Logo';
import { authDefaultPath } from '@/settings/config';

export default function GlobalErrorScreen() {
    const onClickHome = () => {
        window.location.href = authDefaultPath;
    };
    const onClickRefresh = () => {
        window.location.reload();
    };

    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            width={1}
            height='100vh'
            sx={{ backgroundColor: 'grey.100' }}
        >
            <Stack
                justifyContent='center'
                alignItems='center'
                sx={{ backgroundColor: 'white', pt: 9, pb: 10, px: 14, m: 4 }}
            >
                <ErrorOutlineIcon sx={{ fontSize: 64, marginBottom: 2.5, color: '#dabff6' }} />
                <Typography variant='h5' sx={{ marginBottom: 2 }}>
                    서비스에 접속할 수 없습니다.
                </Typography>
                <Typography sx={{ color: 'grey.500' }}>
                    일시적인 시스템 오류로 인해 서비스에 접속이 되지 않았습니다.
                </Typography>
                <Typography sx={{ color: 'grey.500' }}>잠시 후 다시 접속하여 주시기 바랍니다. </Typography>
                <Typography sx={{ color: 'grey.500' }}>이용에 불편을 드려 죄송합니다. </Typography>
                <Stack direction='row' spacing={1.5} marginTop={4.5}>
                    <Button
                        onClick={onClickRefresh}
                        variant='outlined'
                        sx={{ borderColor: 'grey.300', color: 'grey.900' }}
                    >
                        새로고침
                    </Button>
                    <Button
                        onClick={onClickHome}
                        variant='outlined'
                        sx={{ borderColor: 'grey.300', color: 'grey.900' }}
                    >
                        Linkport 홈 가기
                    </Button>
                </Stack>
            </Stack>
            <Logo currentColor='#777' width={100} height={50} />
        </Stack>
    );
}
