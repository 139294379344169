import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    border: `1px solid ${theme.palette.divider}`,
                    '&:not(:last-of-type)': {
                        borderBottom: 0,
                    },
                    '&:before': {
                        display: 'none',
                    },
                    '&.Mui-expanded': {
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingLeft: theme.spacing(2.5),
                    paddingRight: theme.spacing(2),
                    backgroundColor: theme.palette.grey[100],
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    '&.Mui-disabled': {
                        opacity: 1,
                        color: theme.palette.action.disabled,
                        '& .MuiTypography-root': {
                            color: 'inherit',
                        },
                    },
                },
                expandIconWrapper: {
                    color: 'inherit',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                },
            },
        },
    };
}
