import { Theme } from '@mui/material/styles';
import { TabProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
    return {
        MuiTabs: {
            defaultProps: {
                textColor: 'inherit',
                allowScrollButtonsMobile: true,
                variant: 'scrollable',
            },
            styleOverrides: {
                scrollButtons: {
                    width: 48,
                    borderRadius: '50%',
                },
                indicator: {
                    display: 'none',
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
                iconPosition: 'start',
            },
            styleOverrides: {
                root: ({ ownerState }: { ownerState: TabProps }) => ({
                    fontSize: 9,
                    padding: 12,
                    minWidth: 48,
                    fontWeight: theme.typography.fontWeightMedium,
                    '&:not(:last-of-type)': {
                        // marginRight: theme.spacing(2),
                        // [theme.breakpoints.up('sm')]: {
                        //     marginRight: theme.spacing(0),
                        // },
                    },
                    '&:not(.Mui-selected)::after': {
                        content: '""',
                        display: 'inline-block',
                        position: 'absolute',
                        bottom: 16,
                        right: 0,
                        backgroundColor: theme.palette.grey[300],
                        width: '1px',
                        height: '16px',
                    },
                }),
            },
        },
    };
}
