import { privateInstance } from 'api/axiosInstance';
import { useQuery } from 'api/queryClient';
import { UseQueryOptions } from '@tanstack/react-query';

export const NOTIFICATIONS_QUERY_KEY = ['notifications'];

const notifications = (params: any) => {
    return privateInstance.get('/v1/notifications', { params });
};

export const useNotificationsQuery = (params: any, options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...NOTIFICATIONS_QUERY_KEY, params], () => notifications(params), {
        ...options,
    });
};
