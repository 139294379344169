import React from 'react';
import { TreeMenuItem } from 'types/common/tree';

import { Box, Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch } from 'redux/store';
import { add } from 'redux/slices/menu';
import useActiveMenu from 'hooks/useActiveMenu';
import DashboardIcons from 'routes/layouts/dashboard/sidebar/DashboardIcons';
import * as Icons from '@mui/icons-material';
import DashboardList from 'routes/layouts/dashboard/sidebar/DashboardList';

type PropTypes = {
    item: TreeMenuItem;
    depth: number;
};

function DashboardItem({ item, depth }: PropTypes) {
    const dispatch = useDispatch();

    const { title, link } = item;
    const active = useActiveMenu(item.link);

    const handleClick = () => {
        dispatch(add(item));
    };

    return depth === 1 ? (
        <Box sx={{ p: '5px 7px', backgroundColor: active ? 'rgba(131, 135, 180, 0.07)' : 0 }}>
            <ListItem disablePadding onClick={handleClick}>
                <ListItemButton sx={{ p: 0.85 }}>
                    <ListItemIcon sx={{ mr: 1.2, color: active ? 'primary.main' : 'grey.400' }}>
                        <DashboardIcons title={title} />
                    </ListItemIcon>
                    <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                            color: active ? 'grey.800' : 'grey.800',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </Box>
    ) : (
        <ListItem disablePadding>
            <ListItemButton sx={{ p: 0.85, pl: depth * 2.4 }} selected={active} onClick={handleClick}>
                <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                        color: active ? 'primary.main' : 'grey.800',
                        fontWeight: active ? 'bold' : 'normal',
                        fontSize: 14,
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default DashboardItem;
