import {
    QueryClient,
    useQueryClient as useAppQueryClient,
    useQuery as useAppQuery,
    useQueries as useAppQueries,
    useMutation as useAppMutation,
    QueryKey,
    UseQueryOptions,
    QueryFunction,
    UseQueryResult,
} from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            cacheTime: 1000 * 60,
            staleTime: 1000 * 30,
            refetchOnWindowFocus: false,
            useErrorBoundary: (error: any) => error instanceof AxiosError && error.response?.status !== 200,
            onError: (err: any) => {
                enqueueSnackbar(err);
            },
        },
        mutations: {
            // useErrorBoundary: (error: any) => error instanceof AxiosError && error.response?.status !== 200,
            onError: (err: any) => {
                enqueueSnackbar(err);
            },
        },
    },
});

const useQueryClient = useAppQueryClient;

const useQuery = (
    queryKey: QueryKey,
    queryFn: QueryFunction<any, any>,
    options?: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'>
): UseQueryResult<any> => {
    const queryResult = useAppQuery(queryKey, queryFn, options);

    const getPlaceholderData = () => {
        return options?.placeholderData instanceof Function ? options?.placeholderData() : options?.placeholderData;
    };

    return {
        ...queryResult,
        data: queryResult.isError ? getPlaceholderData() || queryResult.data : queryResult.data,
    };
};

const useQueries = useAppQueries;

const useMutation = useAppMutation;

export { queryClient, useQueryClient, useQuery, useQueries, useMutation };
