import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Link,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { FileDownload } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import _ from 'lodash';

function PaperComponent({ context, ...props }: any) {
    return (
        <Draggable
            onStart={(event: any) => {
                const dialogs = document.querySelectorAll('.MuiDialog-root');
                const dialog = event.target.closest('.MuiDialog-root');
                if (dialogs.length > 1) {
                    dialogs.forEach((i: any) => {
                        i.style.zIndex = 0;
                    });
                }
                dialog.style.zIndex = context;
                context += 1;
            }}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function NoticePopupItem({ notice, index, arr }: any) {
    const context = useRef(1301);
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const toggle = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseToggle = () => {
        setAnchorEl(null);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='sm'
            PaperComponent={(props) => <PaperComponent {...props} context={context.current} />}
            disableEnforceFocus
            style={{ pointerEvents: 'none' }}
            hideBackdrop
            PaperProps={{
                sx: {
                    pointerEvents: 'auto',
                    border: '2px solid',
                    borderColor: 'grey.300',
                    borderRadius: '5px',
                    position: 'absolute',
                    top: index * 20 + 40,
                    left: index * 30 + 240,
                    height: 700,
                    cursor: 'move',
                },
            }}
        >
            <DialogTitle>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>{notice?.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction='row' alignItems='center'>
                    <Stack flex={1} direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <Typography variant='body2'>작성자 : {notice?.user?.name?.display}</Typography>
                            <Typography variant='body2'>
                                작성일시 : {dayjs(notice?.created_at).format('YYYY.MM.DD')}
                            </Typography>
                        </Stack>
                        {notice?.files?.length ? (
                            <Button variant='contained' size='small' onClick={handleOpen}>
                                첨부파일 다운로드
                            </Button>
                        ) : (
                            ''
                        )}
                        <Menu anchorEl={anchorEl} open={toggle} onClose={handleCloseToggle}>
                            {notice?.files?.map((file: any) => (
                                <MenuItem key={file.key} onClick={handleCloseToggle}>
                                    <Link
                                        href={file.url}
                                        underline='none'
                                        sx={{
                                            color: 'black',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {file.originalname}
                                        <FileDownload />
                                    </Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogContent sx={{ height: 400 }}>
                <Divider sx={{ my: 2 }} />
                <Stack flex='1 1 0px' overflow='auto'>
                    <Stack flex={1}>
                        <Box dangerouslySetInnerHTML={{ __html: notice?.content }} />
                    </Stack>
                </Stack>
            </DialogContent>
            <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ p: 4 }}>
                <Button
                    variant='outlined'
                    onClick={() => {
                        const getArr: any = localStorage.getItem('noShow') ? localStorage.getItem('noShow') : [];
                        if (!arr.current.length) {
                            arr.current.push(...arr.current, notice._id);
                            localStorage.setItem('noShow', JSON.stringify(_.uniq(arr.current)));
                        } else if (arr.current.length) {
                            const newArr = JSON.parse(getArr);
                            newArr.push(notice._id);
                            localStorage.setItem('noShow', JSON.stringify(_.uniq(newArr)));
                        }
                        setOpen(false);
                    }}
                >
                    다시보지 않기
                </Button>
                <Button variant='contained' onClick={() => setOpen(false)}>
                    닫기
                </Button>
            </Stack>
        </Dialog>
    );
}
export default NoticePopupItem;
