// Import the necessary Firebase modules
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyDSMvBmmx1GcF0JxGqTuEfiWLDoiLVneQA',
    authDomain: 'linkport-618e9.firebaseapp.com',
    projectId: 'linkport-618e9',
    storageBucket: 'linkport-618e9.appspot.com',
    messagingSenderId: '116229628305',
    appId: '1:116229628305:web:de8663e5df9ec5ff303a30',
    measurementId: 'G-L2E4C7MBXQ',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const setupNotifications = async () => {
    try {
        const isNotificationSupported = 'Notification' in window;
        const isServiceWorkerSupported = 'serviceWorker' in navigator;

        if (!isNotificationSupported || !isServiceWorkerSupported) {
            console.warn('This browser does not support notifications or service workers.');
            return;
        }

        const supported = await isSupported();
        if (!supported) {
            throw new Error("This browser doesn't support the API's required to use the Firebase SDK.");
        }
        const messaging = getMessaging(firebaseApp);

        const reg = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered:', reg);

        const readyReg = await navigator.serviceWorker.ready;
        console.log('Service Worker is ready:', readyReg);

        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get the FCM token
            const fcmToken = await getToken(messaging);
            if (fcmToken) {
                console.log('getToken:', fcmToken);
                localStorage.setItem('fcm_token', fcmToken);
                console.log('localStorage setItem fcm_token');
            } else {
                console.warn('No registration token available. Request permission to generate one.');
            }
        } else {
            console.log('Notification permission denied.');
        }
        onMessage(messaging, (payload) => {
            console.log('Foreground Message mainPage:', payload);
            // Handle the notification or update your UI
        });
    } catch (error: any) {
        console.error('Error setting up notifications:', error);
        if (error.message.includes("Failed to execute 'subscribe' on 'PushManager'")) {
            console.error('Failed to subscribe to PushManager: No active Service Worker.');
        } else if (error.code === 'installations/app-offline') {
            console.error('Application is offline.');
        } else if (error.code === 'messaging/unsupported-browser') {
            console.error('This browser does not support the necessary APIs for Firebase Messaging.');
        } else {
            console.error(error);
        }
    }
};

export const setupNotificationsInbound = async (setError: any, fetchFcmToken: any) => {
    try {
        const isNotificationSupported = 'Notification' in window;
        const isServiceWorkerSupported = 'serviceWorker' in navigator;

        if (!isNotificationSupported || !isServiceWorkerSupported) {
            console.warn('This browser does not support notifications or service workers.');
            return;
        }

        const supported = await isSupported();
        if (!supported) {
            throw new Error("This browser doesn't support the API's required to use the Firebase SDK.");
        }
        const messaging = getMessaging(firebaseApp);

        const reg = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered:', reg);

        const readyReg = await navigator.serviceWorker.ready;
        console.log('Service Worker is ready:', readyReg);

        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get the FCM token
            const fcmToken = await getToken(messaging);
            if (fcmToken) {
                console.log('getToken:', fcmToken);
                localStorage.setItem('fcm_token', fcmToken);
                console.log('localStorage setItem fcm_token');
                fetchFcmToken();
            } else {
                console.warn('No registration token available. Request permission to generate one.');
            }
        } else {
            console.log('Notification permission denied.');
        }

        onMessage(messaging, (payload) => {
            console.log('Foreground Message mainPage:', payload);
            // Handle the notification or update your UI
        });
    } catch (error: any) {
        setError(`${error}\n[FCM Error] 새로고침 후 다시 진행해주세요`);
        console.error('Error setting up notifications:', error);
        localStorage.removeItem('fcm_token');
        if (error.message.includes("Failed to execute 'subscribe' on 'PushManager'")) {
            console.error('Failed to subscribe to PushManager: No active Service Worker.');
        } else if (error.code === 'installations/app-offline') {
            console.error('Application is offline.');
        } else if (error.code === 'messaging/unsupported-browser') {
            console.error('This browser does not support the necessary APIs for Firebase Messaging.');
        }
    }
};
