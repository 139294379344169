import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RedeemIcon from '@mui/icons-material/Redeem';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PaidIcon from '@mui/icons-material/Paid';
import AppsIcon from '@mui/icons-material/Apps';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

function DashboardIcons({ title }: any) {
    switch (title) {
        case '대시보드':
            return <DashboardIcon fontSize='small' />;
        case '물류비견적/시뮬레이션':
            return <RequestQuoteIcon fontSize='small' />;
        case '상세검색':
            return <ManageSearchIcon fontSize='small' />;
        case '주문 관리':
            return <ReceiptLongIcon fontSize='small' />;
        case '입출고 관리':
            return <LocalShippingIcon fontSize='small' />;
        case '통관 관리':
            return <LocalPoliceIcon fontSize='small' />;
        case 'CS 문의':
            return <SupportAgentIcon fontSize='small' />;
        case '기준 정보 관리':
            return <AccountTreeIcon fontSize='small' />;
        case '이벤트 관리':
            return <RedeemIcon fontSize='small' />;
        case '정산 관리':
            return <PaidIcon fontSize='small' />;
        default:
            return <AppsIcon fontSize='small' />;
    }
}

export default DashboardIcons;
