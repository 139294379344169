import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
    selected: number;
};

const initialState: State = {
    selected: 0,
};

export const noticeIdSlice = createSlice({
    name: 'noticeId',
    initialState,
    reducers: {
        select: (state, action: PayloadAction<number>) => {
            const { payload } = action;

            state.selected = payload;
        },
    },
});

export const { select } = noticeIdSlice.actions;
export default noticeIdSlice.reducer;
