import React, { useState } from 'react';
import AuthGuard from 'routes/guards/AuthGuard';
import { Stack } from '@mui/material';
import DashboardHeader from 'routes/layouts/dashboard/header/DashboardHeader';
import DashboardSideBar from 'routes/layouts/dashboard/sidebar/DashboardSideBar';
import DashboardMain from 'routes/layouts/dashboard/main/DashboardMain';
import DashboardThemeProvider from 'theme/DashboardThemeProvider';
import ReLoginDialog from 'routes/layouts/dashboard/ReLoginDialog';
import NoticeModal from 'sections/cs/notice/NoticeModal';

function DashboardLayout() {
    const [toggle, setToggle] = useState(true);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <AuthGuard>
            <DashboardThemeProvider>
                <NoticeModal />
                <Stack component='main' direction='row' minWidth={1080} minHeight={1}>
                    <DashboardHeader handleToggle={handleToggle} toggle={toggle} />
                    <DashboardSideBar toggle={toggle} />
                    <DashboardMain />
                    <ReLoginDialog />
                </Stack>
            </DashboardThemeProvider>
        </AuthGuard>
    );
}

export default DashboardLayout;
