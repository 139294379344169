import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeMenuItem } from 'types/common/tree';

type State = {
    selected: string;
    tabs: TreeMenuItem[];
};

const initialState: State = {
    selected: '',
    tabs: [],
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        select: (state, action: PayloadAction<string>) => {
            const { payload } = action;

            state.selected = payload;
        },
        add: (state, action: PayloadAction<TreeMenuItem>) => {
            const { tabs } = state;
            const { payload } = action;

            if (!tabs.some((tab) => tab.link === payload.link)) {
                state.tabs = [...tabs, payload];
            }
            state.selected = payload.link;
        },
        remove: (state, action: PayloadAction<TreeMenuItem>) => {
            const { selected, tabs } = state;
            const { payload } = action;

            const newTabs = tabs.filter((tab) => tab.link !== payload.link);
            state.tabs = newTabs;

            if (selected === payload.link) {
                state.selected = newTabs.length ? newTabs[newTabs.length - 1].link : '';
            }
        },
        errorRemove: (state) => {
            const { selected, tabs } = state;

            const newTabs = tabs.filter((tab) => tab.link !== selected);
            state.tabs = newTabs;

            state.selected = newTabs.length ? newTabs[newTabs.length - 1].link : '';
        },
    },
});

export const { select, add, remove, errorRemove } = menuSlice.actions;
export default menuSlice.reducer;
