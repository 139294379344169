import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
    selected: any;
};

const initialState: State = {
    selected: '',
};

export const userIdSlice = createSlice({
    name: 'userId',
    initialState,
    reducers: {
        select: (state, action: PayloadAction<string>) => {
            const { payload } = action;

            state.selected = payload;
        },
    },
});

export const { select } = userIdSlice.actions;
export default userIdSlice.reducer;
