import React, { useEffect, useMemo } from 'react';
import { TreeData, TreeMenu, TreeMenuItem } from 'types/common/tree';
import { Box, Drawer, Toolbar } from '@mui/material';
import DashboardList from 'routes/layouts/dashboard/sidebar/DashboardList';
import useMenusQuery from 'api/hooks/menus/menusQuery';
import ProgressScreen from 'components/page/loading/ProgressScreen';
import { useDispatch } from 'redux/store';
import { add } from 'redux/slices/menu';

type PropTypes = {
    toggle: boolean;
};

const drawerWidth = 230;

const treeToMenus = (arr: TreeData, defaultLink: string): TreeMenu =>
    arr.map((treeItem) => {
        const { icon, title, path, children, element, is_main } = treeItem;
        const link = `${defaultLink}/${path}`;

        return {
            icon,
            title,
            link,
            element,
            is_main,
            children: children && treeToMenus(children, link),
        };
    });

const findMain = (menus: TreeMenu) => {
    return menus.find((item) => item.is_main);
};

const findDeepMain = (menus: TreeMenu): TreeMenuItem | undefined => {
    for (let i = 0; i < menus.length; i += 1) {
        const item = menus[i];

        if (!item.children) {
            return item;
        }

        const foundInChildren = findDeepMain(item.children);
        if (foundInChildren) {
            return foundInChildren;
        }
    }

    return undefined;
};

function DashboardSideBar({ toggle }: PropTypes) {
    const dispatch = useDispatch();

    const {
        data: { menus = [] },
        isLoading,
    } = useMenusQuery({
        placeholderData: () => [],
    });

    const menu = useMemo(() => treeToMenus(menus, '/dashboard'), [menus]);

    useEffect(() => {
        if (!menu) return;

        const index = findMain(menu) || findDeepMain(menu);

        if (!index) return;

        dispatch(add(index));
    }, [dispatch, menu]);

    return (
        <Drawer
            open={toggle}
            anchor='left'
            variant='persistent'
            sx={{
                width: toggle ? drawerWidth : 0,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: toggle ? drawerWidth : 0,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Toolbar variant='dense' sx={{ minHeight: 56, height: 56 }} />
            <Box sx={{ overflow: 'auto' }}>{isLoading ? <ProgressScreen /> : <DashboardList menu={menu} />}</Box>
        </Drawer>
    );
}

export default DashboardSideBar;
