import { useSelector } from 'redux/store';

// ----------------------------------------------------------------------

export default function useActiveMenu(path: string): boolean {
    const { selected } = useSelector(({ menu }) => ({
        selected: menu.selected,
    }));

    return path && selected ? selected.includes(path) : false;
}
