import React, { PropsWithChildren, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    ThemeOptions,
    StyledEngineProvider,
    ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//
import palette from './options/palette';
import typography from './options/typography';
import shadows from './options/shadows';
import customShadows from './options/customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }: PropsWithChildren) {
    const themeMode = 'light';
    const themeDirection = 'ltr';

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: palette(themeMode),
            typography,
            shape: { borderRadius: 0 },
            direction: themeDirection,
            shadows: shadows(themeMode),
            customShadows: customShadows(themeMode),
        }),
        [themeDirection, themeMode]
    );

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledEngineProvider injectFirst>
                <MUIThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles />
                    {children}
                </MUIThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
}
