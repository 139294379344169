import { usePopupsQuery } from 'api/hooks/notices/noticesQuery';
import NoticePopupItem from 'sections/cs/notice/NoticePopupItem';
import uuid from 'react-uuid';
import { useRef, memo } from 'react';

const NoticeModal = memo(function PopupModal() {
    const noShowArr: any = JSON.parse(localStorage.getItem('noShow') || '[]');
    const arr = useRef(noShowArr);

    const {
        data: { count = 0, notices = [] },
    } = usePopupsQuery({
        placeholderData: () => ({ count: 0, notices: [] }),
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: Infinity,
    });

    return (
        <>
            {notices
                .filter((i: any) => !noShowArr?.includes(i._id))
                .map((notice: any, index: any) => {
                    return <NoticePopupItem notice={notice} index={index} key={uuid()} arr={arr} />;
                })}
        </>
    );
});

export default NoticeModal;
