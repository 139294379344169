import { Theme, alpha } from '@mui/material/styles';
import { ToggleButtonProps } from '@mui/material';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const;

export default function ToggleButton(theme: Theme) {
    const rootStyle = () => ({
        color: theme.palette.grey[700],
        padding: '14px 36px',
        border: 0,
        borderRadius: 6,
        backgroundColor: `${alpha(theme.palette.grey[500], 0.1)}`,
        svg: {
            color: alpha(theme.palette.grey[500], 0.4),
            marginRight: 6,
        },
        '&:hover': {
            svg: {
                color: alpha(theme.palette.primary.main, 0.7),
            },
            color: alpha(theme.palette.primary.main, 0.8),
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
        '&.Mui-selected': {
            svg: {
                color: theme.palette.primary.main,
            },
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.3),
            },
        },
        '&.Mui-disabled': {
            '&.Mui-selected': {
                color: theme.palette.action.disabled,
                backgroundColor: theme.palette.action.selected,
                borderColor: theme.palette.action.disabledBackground,
            },
        },
    });

    return {
        MuiToggleButton: {
            styleOverrides: {
                root: () => rootStyle(),
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    // backgroundColor: `${alpha(theme.palette.grey[500], 0.04)}`,
                    // border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                },
                grouped: {
                    borderColor: 'transparent !important',
                    borderRadius: `6px !important`,
                },
            },
        },
    };
}
