import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { dashboardDefaultPath } from '@/settings/config';
import GuestLayout from 'routes/layouts/guest/GuestLayout';
import DashboardLayout from 'routes/layouts/dashboard/DashboardLayout';
import DynamicLoader from 'routes/DynamicLoader';

function RouterProvider() {
    return useRoutes([
        // {
        //     index: true,
        //     element: <Navigate to={dashboardDefaultPath} replace />,
        // },

        // Auth
        {
            path: 'auth',
            element: <GuestLayout />,
            children: [
                {
                    path: 'signIn',
                    element: <DynamicLoader component='LazySignInPage' />,
                },
                {
                    path: 'findPassword',
                    element: <DynamicLoader component='LazyFindPasswordPage' />,
                },

                {
                    path: 'signUp',
                    element: <DynamicLoader component='LazySignUpPage' />,
                },
            ],
        },
        // Dashboard
        {
            // path: 'dashboard',
            index: true,
            element: <DashboardLayout />,
        },
        {
            path: 'callback/*',
            element: <DynamicLoader component='LazyEmailVerifyPage' />,
        },
        {
            path: 'resetPassword/*',
            element: <DynamicLoader component='LazyResetPasswordPage' />,
        },
        {
            path: 'tracking/*',
            element: <DynamicLoader component='LazyTrackingPage' />,
        },
        { path: '*', element: <Navigate to={dashboardDefaultPath} replace /> },
    ]);
}

export default RouterProvider;
