import React, { PropsWithChildren } from 'react';
import { useAuthQuery } from 'api/hooks/users/usersQuery';
import { Navigate } from 'react-router-dom';

function GuestGuard({ children }: PropsWithChildren) {
    const { data: user } = useAuthQuery();

    if (user) {
        return <Navigate to='/dashboard' />;
    }

    return <>{children}</>;
}

export default GuestGuard;
