import React from 'react';
import { Stack, Toolbar } from '@mui/material';
import { TabContext } from '@mui/lab';
import { useDispatch, useSelector } from 'redux/store';
import { remove, select } from 'redux/slices/menu';
import { TreeMenuItem } from 'types/common/tree';
import DashboardTabs from 'routes/layouts/dashboard/main/DashboardTabs';
import DashboardTabPanels from 'routes/layouts/dashboard/main/DashboardTabPanels';

function DashboardMain() {
    const dispatch = useDispatch();

    const { selected, tabs } = useSelector(({ menu }) => ({
        selected: menu.selected,
        tabs: menu.tabs,
    }));

    const handleChange = (event: React.SyntheticEvent, link: string) => {
        dispatch(select(link));
    };

    const handleClose = (event: React.MouseEvent, tab: TreeMenuItem) => {
        event.stopPropagation();
        dispatch(remove(tab));
    };

    return (
        <Stack component='section' flex={1} overflow='hidden'>
            <Toolbar variant='dense' sx={{ minHeight: 56, height: 56 }} />
            <TabContext value={selected}>
                <DashboardTabs tabs={tabs} handleChange={handleChange} handleClose={handleClose} />
                <DashboardTabPanels selected={selected} tabs={tabs} />
            </TabContext>
        </Stack>
    );
}

export default DashboardMain;
