import { isLoggedIn, privateInstance } from 'api/axiosInstance';
import { useQuery } from 'api/queryClient';
import { UseQueryOptions } from '@tanstack/react-query';
import { User } from 'types/users/users';

export const USERS_QUERY_KEY = ['users'];

const auth = (): Promise<User | null> => {
    if (!isLoggedIn()) return Promise.resolve(null);

    return privateInstance.get('/v1/users/me');
};

export const useAuthQuery = (options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery(USERS_QUERY_KEY, () => auth(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        ...options,
    });
};

const tenant = () => {
    return privateInstance.get('v1/users/me/tenant');
};

export const useUserTenantQuery = (options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...USERS_QUERY_KEY, 'tenant'], () => tenant(), {
        ...options,
    });
};

const users = (params: any) => {
    return privateInstance.get('/v1/users', { params });
};

export const useUsersQuery = (params: any, options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...USERS_QUERY_KEY, params], () => users(params), {
        ...options,
    });
};

const user = (id: any) => {
    return privateInstance.get(`/v1/users/${id}`);
};

export const useUserQuery = (id: any, options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...USERS_QUERY_KEY, id], () => user(id), {
        ...options,
    });
};
