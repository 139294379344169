import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
    selected: string;
};

const initialState: State = {
    selected: '',
};

export const sellerIdSlice = createSlice({
    name: 'sellerId',
    initialState,
    reducers: {
        select: (state, action: PayloadAction<string>) => {
            const { payload } = action;

            state.selected = payload;
        },
    },
});

export const { select } = sellerIdSlice.actions;
export default sellerIdSlice.reducer;
