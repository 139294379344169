import { alpha, Theme } from '@mui/material/styles';
import { ButtonProps, InputProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
    const rootStyle = (ownerState: InputProps) => {
        const smallSize = ownerState.size === 'small';
        const mediumSize = ownerState.size === 'medium';
        const size = {
            ...(smallSize && {
                height: 34,
                fontSize: 12,
            }),
            ...(mediumSize && {
                height: 42,
                fontSize: 15,
                borderRadius: 4,
            }),
        };
        const outlined = {
            borderRadius: 3,
            backgroundColor: theme.palette.common.white,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(theme.palette.grey[500], 0.4),
            },
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 1,
                    borderColor: theme.palette.text.primary,
                },
            },
            '&.Mui-disabled': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.action.disabledBackground,
                },
            },
        };
        return [size, outlined];
    };

    return {
        MuiInputBase: {
            styleOverrides: {
                // root: {
                //     '&.Mui-disabled': {
                //         '& svg': {
                //             color: theme.palette.text.disabled,
                //         },
                //     },
                // },
                root: {
                    '&.MuiInputBase-multiline': {
                        height: 'auto',
                    },
                },
                input: {
                    // '&::placeholder': {
                    //     opacity: 1,
                    //     color: theme.palette.text.disabled,
                    // },
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottomColor: alpha(theme.palette.grey[500], 0.56),
                    },
                    '&:after': {
                        borderBottomColor: theme.palette.text.primary,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        fontSize: 12,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    fontSize: 13,
                    borderRadius: 4,
                    padding: 14,
                    backgroundColor: alpha(theme.palette.grey[500], 0.08),
                    '&:hover': {
                        // backgroundColor: alpha(theme.palette.grey[500], 0.14),
                        backgroundColor: 'white',
                    },
                    '&.Mui-focused': {
                        // backgroundColor: alpha(theme.palette.grey[500], 0.14),
                        backgroundColor: 'white',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.action.disabledBackground,
                    },
                },
                underline: {
                    '&:before, :after': {
                        display: 'none',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ ownerState }: { ownerState: InputProps }) => rootStyle(ownerState),
            },
        },
    };
}
