import React, { useState } from 'react';
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { User } from 'types/users/users';
import { useDispatch } from 'redux/store';
import { add } from 'redux/slices/menu';
import { Logout, ManageAccounts } from '@mui/icons-material';

type PropTypes = {
    auth: User;
    logout?: () => any;
};

function DashboardProfile({ auth, logout }: PropTypes) {
    const dispatch = useDispatch();

    const {
        user: {
            name: { display },
            roles,
            username,
        },
    } = auth;
    const roleName = roles[0]?.name ?? '';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleEditProfile = () => {
        // TODO URL 정리 필요
        if (roleName === 'root' || roleName === 'admin') {
            dispatch(
                add({
                    title: '가입 정보 관리',
                    link: '/dashboard/information/sign',
                    element: 'LazySignManagePage',
                })
            );
            handleClose();
        } else {
            dispatch(
                add({
                    title: '가입 정보 관리(User)',
                    link: '/dashboard/information/signUser',
                    element: 'LazySignUserManagePage',
                })
            );
            handleClose();
        }
    };

    const handleSignOut = () => {
        if (!logout) return;
        logout();
    };

    return (
        <>
            <IconButton onClick={handleOpen} sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', height: 36, width: 36 }}>
                <PersonIcon sx={{ color: 'rgba(255, 255, 255)' }} />
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.1))',
                        mt: 1.2,
                        borderRadius: '6px',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 20,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                        '.MuiMenu-list': {
                            p: 0,
                        },
                    },
                }}
            >
                <Stack py={2} px={3}>
                    <Stack direction='row'>
                        <Typography variant='subtitle1'>{display}</Typography>
                        <Typography sx={{ ml: 1, color: 'primary.main' }}>{roleName}</Typography>
                    </Stack>
                    <Typography variant='body2' color='grey.500'>
                        {username}
                    </Typography>
                </Stack>
                <Divider />
                <Stack direction='row' divider={<Divider orientation='vertical' flexItem />}>
                    <MenuItem onClick={handleEditProfile} sx={{ width: 120, p: 1.5, justifyContent: 'center' }}>
                        <ManageAccounts fontSize='small' />
                        <Typography sx={{ ml: 0.5 }}>내 정보</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleSignOut} sx={{ width: 120, justifyContent: 'center' }}>
                        <Logout fontSize='small' />
                        <Typography sx={{ ml: 0.5 }}>로그아웃</Typography>
                    </MenuItem>
                </Stack>
            </Menu>
        </>
    );
}

export default DashboardProfile;
