import React from 'react';
import GuestGuard from 'routes/guards/GuestGuard';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

function GuestLayout() {
    return (
        <GuestGuard>
            <Stack component='main' direction='row' minWidth={1080} minHeight={1}>
                <Outlet />
            </Stack>
        </GuestGuard>
    );
}

export default GuestLayout;
