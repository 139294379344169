import React from 'react';
import { TreeMenu } from 'types/common/tree';

import { List } from '@mui/material';

import DashboardCollapse from 'routes/layouts/dashboard/sidebar/DashboardCollapse';
import DashboardItem from 'routes/layouts/dashboard/sidebar/DashboardItem';

type PropTypes = {
    menu: TreeMenu;
    depth?: number;
};

function DashboardList({ menu, depth = 1 }: PropTypes) {
    return (
        <List disablePadding>
            {menu.map((item) =>
                item.children?.length ? (
                    <DashboardCollapse key={item.link} item={item} depth={depth} />
                ) : (
                    <DashboardItem key={item.link} item={item} depth={depth} />
                )
            )}
        </List>
    );
}

export default DashboardList;
