import React from 'react';
import { LinearProgress, Stack } from '@mui/material';

function ProgressScreen() {
    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            width={1}
            height={1}
            zIndex={9998}
            sx={{ backgroundColor: (theme) => theme.palette.background.default }}
        >
            <LinearProgress color='inherit' sx={{ width: 1, maxWidth: 360 }} />
        </Stack>
    );
}

export default ProgressScreen;
