import { combineReducers } from 'redux';
import menu from 'redux/slices/menu';
import sellerId from '@/redux/slices/sellerId';
import sellerAdmin from '@/redux/slices/sellerAdmin';
import orderId from '@/redux/slices/orderId';
import masterId from '@/redux/slices/masterId';
import subMasterId from '@/redux/slices/subMasterId';
import noticeId from '@/redux/slices/noticeId';
import userId from '@/redux/slices/userId';
import filtersModel from '@/redux/slices/filtersModel';

const rootReducer = combineReducers({
    menu,
    sellerId,
    sellerAdmin,
    orderId,
    masterId,
    subMasterId,
    noticeId,
    userId,
    filtersModel,
});

export default rootReducer;
