import React from 'react';
import { AppBar, IconButton, Link, Stack, Toolbar } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Logo from 'components/aseets/Logo';
import DashboardTimer from 'routes/layouts/dashboard/header/DashboardTimer';
import DashboardSearch from 'routes/layouts/dashboard/header/DashboardSearch';
import DashboardNotifications from 'routes/layouts/dashboard/header/DashboardNotifications';
import DashboardProfile from 'routes/layouts/dashboard/header/DashboardProfile';
import { useAuthQuery } from 'api/hooks/users/usersQuery';
import { clearAuthTokens } from 'api/axiosInstance';
import { authDefaultPath } from '@/settings/config';

type PropTypes = {
    handleToggle: VoidFunction;
    toggle: boolean;
};

function DashboardHeader({ handleToggle, toggle }: PropTypes) {
    const { data: auth } = useAuthQuery();

    const logout = async () => {
        clearAuthTokens();
        window.location.href = authDefaultPath;
    };

    return (
        <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar variant='dense' sx={{ bgcolor: 'grey.800', minHeight: 54, height: 54 }}>
                <Stack direction='row' spacing={0.8} justifyContent='center' alignItems='center' margin={-2}>
                    <IconButton color='inherit' onClick={handleToggle}>
                        {toggle ? (
                            <MenuOpenIcon opacity={0.6} />
                        ) : (
                            <MenuOpenIcon opacity={0.6} sx={{ transform: 'scaleX(-1)' }} />
                        )}
                    </IconButton>
                    <Link href='/' sx={{ height: 23 }}>
                        <Logo currentColor='#fff' width='90px' />
                    </Link>
                </Stack>
                <Stack direction='row' flex={1} justifyContent='space-between' alignItems='center'>
                    <DashboardTimer />
                    <Stack direction='row' alignItems='center' spacing={1}>
                        {/*<DashboardSearch />*/}
                        <DashboardNotifications />
                        <DashboardProfile auth={auth} logout={logout} />
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

export default DashboardHeader;
