import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    ko: {
        translation: {
            track: '배송조회',
            receiptNumber: '접수번호',
            orderNumber: '주문번호',
            trackingNumber: '운송장번호',
            resendNumber1: '재배송번호1',
            resendNumber2: '재배송번호2',
            Delivery: '운송사',
            noData: '트래킹 정보를 찾을 수 없습니다.',
            info: '접수번호 , 주문번호 , 운송장번호 중 한가지를 입력해주세요.\n여러개인 경우엔 콤마 또는 줄 바꿈으로 구분해주세요.\n최대 30건까지 가능합니다.',
        },
    },
    en: {
        translation: {
            track: 'Track',
            receiptNumber: 'Receipt No',
            orderNumber: 'Order No',
            trackingNumber: 'Tracking No',
            resendNumber1: 'Re-shipment Tracking No 1',
            resendNumber2: 'Re-shipment Tracking No 2',
            Delivery: 'Delivery',
            noData: 'We seem cannot identify the number.',
            info: 'Please enter either the reference number, order number, or tracking number. \nIf you have multiple entries, please separate them with commas or line breaks. \nYou can enter up to 30 entries.',
        },
    },
    zhHans: {
        translation: {
            track: '查询配送',
            receiptNumber: '接收号码',
            orderNumber: '订单号码',
            trackingNumber: '运单号码',
            resendNumber1: '再发送号码1',
            resendNumber2: '再发送号码2',
            Delivery: '物流查询',
            noData: '无法找到跟踪信息。',
            info: '请您输入接收号码、订单号码或运单号码中的一个。\n如果有多个，请用逗号或换行符分隔。',
        },
    },
    zhHant: {
        translation: {
            track: '物流查詢',
            receiptNumber: '接收號碼',
            orderNumber: '訂單號碼',
            trackingNumber: '運單號碼',
            resendNumber1: '再發送號碼1',
            resendNumber2: '再發送號碼2',
            Delivery: '物流查询',
            noData: '無法找到追蹤信息。',
            info: '接收號碼、訂單號碼、運單號碼中選擇一項輸入。\n如有多個，請用逗號或換行符分隔。\n最多可輸入30個。',
        },
    },
    jp: {
        translation: {
            track: 'トラック',
            receiptNumber: '受付番号',
            orderNumber: '注文番号',
            trackingNumber: '運送状番号',
            resendNumber1: '再配達の追跡番号1',
            resendNumber2: '再配達の追跡番号2',
            Delivery: '運輸',
            noData: '数字を確認することができないようです。',
            info: '受付番号、注文番号、または追跡番号のいずれかを入力してください。\n複数のエントリーがある場合は、コンマまたは改行で区切ってください。\n最大30件まで入力できます。',
        },
    },
};

i18next.use(initReactI18next).init({
    lng: 'ko',
    fallbackLng: 'ko',
    debug: true,
    resources,
});
