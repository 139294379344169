import React from 'react';
import * as LazyPages from '@/settings/routes/LazyPages';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorScreen from 'components/page/error/ErrorScreen';
import { AxiosError } from 'axios';

function Fallback({ error }: any) {
    if (error instanceof AxiosError) {
        throw error;
    }
    return <ErrorScreen />;
}

function DynamicLoader({ component, ...props }: { component: keyof typeof LazyPages }) {
    const DynamicComponent = LazyPages[component];
    return (
        <ErrorBoundary fallbackRender={Fallback}>
            <DynamicComponent {...props} />
        </ErrorBoundary>
    );
}

export default DynamicLoader;
