import { privateInstance } from 'api/axiosInstance';
import { useQuery } from 'api/queryClient';
import { UseQueryOptions } from '@tanstack/react-query';

export const NOTICES_QUERY_KEY = ['notices'];
export const POPUP_QUERY_KEY = ['poopup'];

const notices = (params: any) => {
    return privateInstance.get('/v1/notices', { params });
};

export const useNoticesQuery = (params: any, options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...NOTICES_QUERY_KEY, params], () => notices(params), {
        ...options,
    });
};

const notice = (id: string | number) => {
    return privateInstance.get(`/v1/notices/${id}`);
};

export const useNoticeQuery = (id: string | number, options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...NOTICES_QUERY_KEY, id], () => notice(id), {
        ...options,
    });
};

const popups = () => {
    return privateInstance.get('/v1/notices/popups');
};

export const usePopupsQuery = (options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery([...POPUP_QUERY_KEY], () => popups(), {
        ...options,
    });
};
