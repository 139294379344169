import React, { useEffect, useState } from 'react';
import { TreeMenuItem } from 'types/common/tree';
import { Box, Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import useActiveMenu from 'hooks/useActiveMenu';
import DashboardList from 'routes/layouts/dashboard/sidebar/DashboardList';
import DashboardIcons from 'routes/layouts/dashboard/sidebar/DashboardIcons';

type PropTypes = {
    item: TreeMenuItem;
    depth: number;
};

// function Icon({ component = 'Apps', ...props }: { component?: keyof typeof Icons }) {
//     const DynamicComponent = Icons[component];
//     return <DynamicComponent {...props} />;
// }

function DashboardCollapse({ item, depth }: PropTypes) {
    const { title, children } = item;

    const [flag, setFlag] = useState(false);

    const { pathname } = useLocation();
    const active = useActiveMenu(item.link);

    const handleClick = () => {
        setFlag(!flag);
    };

    useEffect(() => {
        if (title === '주문 관리' || title === '입출고 관리') setFlag(true);
        // if (!active) return;
        // setFlag(active);
    }, [active, pathname, title]);

    return (
        <Box sx={{ p: '5px 7px', backgroundColor: active ? 'rgba(131, 135, 180, 0.07)' : 0 }}>
            <ListItem disablePadding onClick={handleClick}>
                <ListItemButton sx={{ p: 0.85 }}>
                    <ListItemIcon sx={{ mr: 1.2, color: active ? 'primary.main' : 'grey.400' }}>
                        <DashboardIcons title={title} />
                    </ListItemIcon>
                    <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                            color: active ? 'grey.800' : 'grey.800',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    />
                    {flag ? (
                        <Icons.ExpandLess sx={{ color: 'grey.400', fontSize: 'large' }} />
                    ) : (
                        <Icons.ExpandMore sx={{ color: 'grey.400', fontSize: 'large' }} />
                    )}
                </ListItemButton>
            </ListItem>
            {children && (
                <Collapse in={flag}>
                    <DashboardList menu={children} depth={depth + 1} />
                </Collapse>
            )}
        </Box>
    );
}

export default DashboardCollapse;
