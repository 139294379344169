import React, { PropsWithChildren } from 'react';
import { useAuthQuery } from 'api/hooks/users/usersQuery';
import ErrorScreen from 'components/page/error/ErrorScreen';
import { authDefaultPath } from '@/settings/config';
import { clearAuthTokens } from 'api/axiosInstance';
import { useNavigate } from 'react-router-dom';

function AuthProvider({ children }: PropsWithChildren) {
    const { isError, isLoading } = useAuthQuery();

    if (isLoading) {
        return null;
    }

    if (isError) {
        clearAuthTokens();
        window.location.href = authDefaultPath;
        return null;
    }

    return <>{children}</>;
}

export default AuthProvider;
