import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
    selected: string;
};

const initialState: State = {
    selected: '',
};

export const subMasterIdSlice = createSlice({
    name: 'subMasterId',
    initialState,
    reducers: {
        select: (state, action: PayloadAction<string>) => {
            const { payload } = action;

            state.selected = payload;
        },
    },
});

export const { select } = subMasterIdSlice.actions;
export default subMasterIdSlice.reducer;
