import React from 'react';
import { TabPanel } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import { TreeMenuItem } from 'types/common/tree';
import DynamicLoader from 'routes/DynamicLoader';
import DashboardPage from 'pages/DashboardPage';

type PropTypes = {
    selected: string;
    tabs: TreeMenuItem[];
};

function DashboardTabPanels({ selected, tabs }: PropTypes) {
    return (
        <Stack flex={1}>
            {tabs.map((tab) => (
                <TabPanel
                    key={tab.link}
                    value={selected}
                    sx={{
                        display: tab.link === selected ? 'flex' : 'none',
                        flexDirection: 'column',
                        flex: 1,
                        p: 3.5,
                    }}
                >
                    <DynamicLoader component={tab.element} />
                </TabPanel>
            ))}
        </Stack>
    );
}

export default DashboardTabPanels;
