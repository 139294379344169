import { privateInstance } from 'api/axiosInstance';
import { queryClient, useMutation } from 'api/queryClient';
import { UseMutationOptions } from '@tanstack/react-query';
import { NOTIFICATIONS_QUERY_KEY } from 'api/hooks/notifications/notificationsQuery';

const readNotification = async (id: any): Promise<any> => {
    return privateInstance.put(`/v1/notifications/${id}/read`);
};

export const useReadNotifications = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation((id: any) => readNotification(id), {
        ...options,
        onSuccess: async () => {
            await queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY);
        },
    });
};
