import React, { PropsWithChildren } from 'react';
import { useAuthQuery } from 'api/hooks/users/usersQuery';
import { Navigate } from 'react-router-dom';
import { authDefaultPath } from '@/settings/config';

function AuthGuard({ children }: PropsWithChildren) {
    const { data: user } = useAuthQuery();

    if (!user) {
        return <Navigate to={authDefaultPath} />;
    }

    return <>{children}</>;
}

export default AuthGuard;
