import { isLoggedIn, privateInstance } from 'api/axiosInstance';
import { useQuery } from 'api/queryClient';
import { UseQueryOptions } from '@tanstack/react-query';

export const CONFIG_QUERY_KEY = ['config'];

const menus = () => {
    if (!isLoggedIn()) return Promise.resolve([]);
    return privateInstance.get('/v1/menus');
};

const useMenusQuery = (options?: UseQueryOptions<any, any, any, any>) => {
    return useQuery(CONFIG_QUERY_KEY, () => menus(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        ...options,
    });
};

export default useMenusQuery;
